import React from 'react'
import { useCurrent, useResource } from 'components/hooks'
import { COMPANY } from 'constants/resources'
import { Divider } from 'components/ui'
import { Destroy, TableDetail } from 'components/common'
import Employees from './detail/Employees'
import OrderList from './detail/OrderList'
import Header from './detail/Header'
import NotificationList from './detail/NotificationList'
import Information from './detail/Information'

function CompanyDetail() {
  const selected = useCurrent(COMPANY)
  const loading = useResource(COMPANY, 'current', 'loading')

  return (
    <TableDetail>
      <Header title={selected.get('name')} id={selected.get('id')} companyId={selected.get('id')} loading={loading} />
      <Divider spacing={28} />
      <Information
        salesRepName={selected.getIn(['sales_rep', 'name'])}
        salesRepId={selected.getIn(['sales_rep', 'id'])}
        rentalProtection={selected.get('rental_protection')}
        hasAccount={selected.get('has_account')}
        hasSignedRPP={selected.get('has_signed_rpp')}
        currentSupplier={selected.getIn(['metadata', 'current_supplier'])}
        notes={selected.get('notes')}
        emailsList={selected.getIn(['metadata', 'emails', 'list'])}
        emailsOptin={selected.getIn(['metadata', 'emails', 'optin'])}
        emailsLang={selected.getIn(['metadata', 'emails', 'lang'])}
      />
      <Divider spacing={20} />
      <Employees loading={loading} companyName={selected.get('name')} companyId={selected.get('id')} />
      <Divider spacing={20} />
      <OrderList loading={loading} companyId={selected.get('id')} />
      <Divider spacing={20} />
      <NotificationList loading={loading} companyId={selected.get('id')} />
      <Destroy resource={COMPANY} id={selected.get('id')} show />
    </TableDetail>
  )
}

export default CompanyDetail
