import styled from 'styled-components'
import React from 'react'
import { IconButton, NavLink } from 'components/common'
import { useTranslation } from 'react-i18next'
import { Icon } from 'components/ui'
import routes from 'constants/routes'
import PropTypes from 'prop-types'
import Overlay from './Overlay'
import { StyledTabTitle } from 'components/common/Navbar'

const MobileMenu = styled(Overlay)`
  background-color: ${({ theme }) => theme.color.primary};
  z-index: 2;
  left: ${({ show }) => (show ? 0 : '100%')};
  width: 100%;
  transition: 200ms ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 32px 16px;
  /* box-shadow: 0 0 20px rgba(0,0,0,0.6); */

  .menu-close {
    position: absolute;
    right: 16px;
    top: 32px;
  }
  .overlay-links {
    max-height: 70vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .overlay-link {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .overlay-nav-actions {
    position: absolute;
    bottom: 32px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    > * {
      margin-right: 1em;
      &:last-child {
        margin: 0;
      }
    }
  }
`

function NavigationOverlay({
  handleLogoutClick,
  handleHideClick,
  handleNotificationClick,
  isNotification,
  show,
  handleLanguageClick,
  handleThemeClick,
  theme,
  id
}) {
  const { t } = useTranslation('common')
  return (
    <MobileMenu id="hamburger-close" show={show} opacity={1} onClick={handleHideClick}>
      <div className="overlay-nav-actions">
        <IconButton size={36} icon="eject" onClick={handleLogoutClick} />
        <IconButton size={36} icon="notifications" dot={isNotification} onClick={handleNotificationClick} />
        <IconButton size={36} icon={theme.name === 'dark' ? 'brightness_7' : 'brightness_2'} className="theme-switch" onClick={handleThemeClick} />
        <IconButton size={36} icon="translate" onClick={handleLanguageClick} className="language-switch" />
      </div>
      <Icon name="clear" size={30} className="menu-close" />
      <div className="overlay-links">
        <div className="overlay-link">
          <NavLink data-test-id="route-dashboard-mobile" to={{ pathname: routes.dashboard }} target="_blank">
            {t('resources.dashboard.plural')}
          </NavLink>
        </div>
        <div className="overlay-link">
          <NavLink data-test-id="route-companies-mobile" to={routes.companies}>
            {t('resources.companies.plural')}
          </NavLink>
        </div>
        <div className="overlay-link">
          {process.env.REACT_APP_V3_FEATURE_TOGGLE === 'true' ? (
            <StyledTabTitle href={`${process.env.REACT_APP_RC_WEB_V3}/contracts`}>{t('resources.orders.plural')}</StyledTabTitle>
          ) : (
            <NavLink data-test-id="route-orders-mobile" to={routes.orders} exact>
              {t('resources.orders.plural')}
            </NavLink>
          )}
        </div>
        <div className="overlay-link">
          <NavLink data-test-id="route-orders-mobile" to={routes.orderItems} exact>
            {t('resources.orderItems.plural')}
          </NavLink>
        </div>
        <div className="overlay-link">
          <NavLink data-test-id="route-equipment-mobile" exact to={routes.equipment}>
            {t('resources.equipment.plural')}
          </NavLink>
        </div>
        <div className="overlay-link">
          <NavLink data-test-id="route-categories-mobile" exact to={routes.categories}>
            {t('resources.categories.plural')}
          </NavLink>
        </div>
        <div className="overlay-link">
          <NavLink data-test-id="route-users-mobile" to={routes.users}>
            {t('resources.users.plural')}
          </NavLink>
        </div>
        <div className="overlay-link">
          <NavLink data-test-id="route-suppliers-mobile" to={routes.suppliers}>
            {t('resources.suppliers.plural')}
          </NavLink>
        </div>
        <div className="overlay-link">
          {process.env.REACT_APP_V3_FEATURE_TOGGLE === 'true' ? (
            <StyledTabTitle href={`${process.env.REACT_APP_RC_WEB_V3}/extras`}>{t('resources.extras.plural')}</StyledTabTitle>
          ) : (
            <NavLink data-test-id="route-extras-mobile" to={routes.extras}>
              {t('resources.extras.plural')}
            </NavLink>
          )}
        </div>
        <div className="overlay-link">
          {process.env.REACT_APP_V3_FEATURE_TOGGLE === 'true' ? (
            <StyledTabTitle href={`${process.env.REACT_APP_RC_WEB_V3}/service-calls`}>{t('resources.serviceRequests.plural')}</StyledTabTitle>
          ) : (
            <NavLink data-test-id="route-service-calls-mobile" to={routes.serviceCalls}>
              {t('resources.serviceRequests.plural')}
            </NavLink>
          )}
        </div>
        <div className="overlay-link">
          <StyledTabTitle href={`${process.env.REACT_APP_RC_WEB_V3}/requests`}>{t('resources.requests.plural')}</StyledTabTitle>
        </div>
      </div>
    </MobileMenu>
  )
}

NavigationOverlay.propTypes = {
  handleLogoutClick: PropTypes.func.isRequired,
  handleHideClick: PropTypes.func.isRequired,
  handleLanguageClick: PropTypes.func.isRequired,
  handleNotificationClick: PropTypes.func.isRequired,
  handleThemeClick: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  isNotification: PropTypes.bool.isRequired,
  theme: PropTypes.string.isRequired,
  id: PropTypes.string
}

export default NavigationOverlay
